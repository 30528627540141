<template>
  <div>
    <div
      class="layout-header-section d-lg-flex d-block justify-content-between"
    >
      <div class="header-label col-lg-6 col-12">Add Report</div>
    </div>
    <div class="search-user-section-outer" style="height: calc(100vh - 141px)">
      <div class="layout-content-section">
        <div class="form-box-outer">
          <div class="row">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    ><span class="text-danger"></span
                  ></label>
                  <div class="input-group">
                    <div class="col-lg-12 col-md-12 col-12">
                      <input
                        type="text"
                        v-model="searchTerm"
                        class="form-control"
                        id="formMobileNoInput"
                        placeholder="Enter Mobile Or Name"
                        @keyup.enter="searchReportingUser(searchTerm)"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="custom-form-group" style="margin-top: 26px">
                  <label class="form-label"></label>
                  <button
                    type="button"
                    class="header-btn-outer btn btn-primary"
                    :style="{ width: '100px' }"
                    @click="searchReportingUser(searchTerm)"
                  >
                    <span v-if="!btnloader">Search</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="btnloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- start for guest listing -->
      <div class="company-section-outer">
        <div class="layout-content-section">
          <div
            class="custom-ultima-datatable custom-voter-datable custom-without-checkbox-datatable"
            style="height: calc(100vh - 141px)"
          >
            <DataTable
              :value="guestList"
              :scrollable="true"
              columnResizeMode="fit"
              scrollHeight="flex"
              :paginator="true"
              :rows="30"
              :totalRecords="totalRecords"
              @page="changePage($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              responsiveLayout="scroll"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :lazy="true"
              dataKey="aa1"
              v-model:selection="selectedGuest"
              :loading="loading"
            >
            <!-- selectionMode="single"
              :metaKeySelection="false"
              @rowSelect="addReportModalOpen(selectedGuest)" -->
              <template #empty>No records found.</template>
              <template #loading>Loading records. Please wait...</template>
              <Column
                header="Select All"
                headerStyle="width: 96%"
                bodyStyle="width: 96%"
              >
                <template #body="{ data }">
                  <div class="company-detail-box-outer mb-3">
                    <div class="row g-2">
                      <div
                        class="col-lg-2 col-md-2 col-12 d-flex align-items-center user-profile-table-col"
                      >
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div>
                              <img
                                v-if="data.aa32 != '' || data.aa32 != null"
                                :src="
                                  'https://storage.googleapis.com/' +
                                  this.hoststorageimgpath +
                                  '/visitor/aadharphoto/' +
                                  data.aa32
                                "
                                  alt="Profile Image"
                                 style="width: 82px; height: 82px"
                              />
                              <img
                                v-else-if ="data.aa46 != '' || data.aa46 != null"
                                :src="
                                  'https://storage.googleapis.com/' +
                                  this.hoststorageimgpath +
                                  '/visitor/profile/' +
                                  data.aa46
                                "
                                  alt="Profile Image"
                                 style="width: 82px; height: 82px"
                              />
                              <img v-else   src="/assets/images/profile.svg"
                              class="rounded bg-light me-auto d-block"
                              alt="Profile Image"
                              style="width: 82px; height: 82px" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12 user-name-table-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Guest Name</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.aa9 ? data.aa9 : "N/A" }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Country<span class="mx-1 vertical-pipe-color"
                                  >|</span
                                >State<span class="mx-1 vertical-pipe-color"
                                  >|</span
                                >City</label
                              >
                              <div class="from-label-value text-capitalize">
                                {{ data.aa18 ? data.aa18 : "N/A"
                                }}<span class="mx-1 vertical-pipe-color">|</span
                                >{{ data.aa20 ? data.aa20 : "N/A"
                                }}<span class="mx-1 vertical-pipe-color">|</span
                                >{{ data.aa22 ? data.aa22 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Gender<span class="mx-1 vertical-pipe-color"
                                  >|</span
                                >Age</label
                              >
                              <div class="from-label-value text-capitalize">
                                {{
                                  data.aa12
                                    ? data.aa12 == 1
                                      ? "Male"
                                      : data.aa12 == 2
                                      ? "Female"
                                      : "Others"
                                    : "N/A"
                                }}<span class="mx-1 vertical-pipe-color">|</span
                                >{{ data.aa11 ? data.aa11 : "N/A" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Guest Type</label>
                              <div class="from-label-value text-capitalize">
                                <span v-if="data.aa30 == 1">Indian</span>
                                <span v-if="data.aa30 == 2">foreigner</span>
                                <span
                                  v-if="data.aa30 == '' || data.aa30 == null"
                                  >N/A</span
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-lg-12 col-md-12 col-12"
                            v-if="data.aa30 == 2"
                          >
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Visa No.<span class="mx-1 vertical-pipe-color"
                                  >|</span
                                >Expiry Date</label
                              >
                              <div class="from-label-value text-uppercase">
                                {{ data.aa39 ? data.aa39 : "N/A"
                                }}<span class="mx-1 vertical-pipe-color">|</span
                                ><span
                                  :class="{ blink_me: isExpired(data.aa40) }"
                                  >{{
                                    format_date(data.aa40 ? data.aa40 : "N/A")
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Visiting Reason</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.aa49 ? data.aa49 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">{{
                                data.aa30 == 1 ? "Aadhar No." : "Passport No."
                              }}</label>
                              <div class="from-label-value text-capitalize">
                                <span v-if="data.aa30 == 1">{{
                                  maskedNumber(data.aa33)
                                }}</span>
                                <span v-else>{{
                                  maskedNumber(data.aa41)
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div
                              class="customer-label-group mb-2 d-flex justify-content-between"
                            >
                              <div>
                                <label class="form-label">Reg. Date Time</label>
                                <div class="from-label-value">
                                  {{ format_timestamp(data.aa26) }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div
                              class="customer-label-group mb-2 d-flex justify-content-between"
                            >
                              <div>
                                <label class="form-label">Mobile</label>
                                <div
                                  class="from-label-value"
                                  v-if="isShowMobileNo == 0"
                                >
                                  +{{ data.aa44 }} {{ maskedNumber(data.aa13) }}
                                </div>
                                <div
                                  class="from-label-value"
                                  v-else-if="isShowMobileNo == 1"
                                >
                                  +{{ data.aa44 }} {{ data.aa13 }}
                                </div>
                                <div class="from-label-value" v-else>
                                  {{ "N/A" }}
                                </div>
                              </div>
                              <div class="d-flex align-items-end">
                                <button
                                  type="button"
                                  class="btn custom-view-detail-btn"
                                  @click="addReportModalOpen(data.aa1)"
                                >
                                  Report
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"
                                >Check-In<span class="mx-1 vertical-pipe-color"
                                  >|</span
                                >Check-Out</label
                              >
                              <div class="from-label-value">
                                {{ format_date(data.aa42)
                                }}<span class="mx-1 vertical-pipe-color">|</span
                                >{{ format_date(data.aa43) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <!-- end for guest listing -->
      <!-- start for report modal form -->
      <div class="modal-mask" v-if="isReportModalOpen">
        <div
          class="modal-dialog modal-sm modal-dialog-centered custom-modal-outer"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Report</h5>
              <button
                type="button"
                class="btn-close"
                @click="addReportModalClose()"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="custom-form-group">
                    <label class="form-label"
                      >Reason to Report<span class="text-danger">*</span></label
                    >
                    <Multiselect
                      v-model="reportedReason"
                      :options="reportedReasonList"
                      :searchable="true"
                      label="label"
                      placeholder="Select Report Reason"
                      class="multiselect-custom text-capitalize"
                      :canClear="true"
                      :closeOnSelect="true"
                      :object="true"
                    />
                    <div class="custom-error" v-if="v$.reportedReason.$error">
                      {{ v$.reportedReason.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label for="formShortNameInput" class="form-label"
                    >Mention in brief<span class="text-danger">*</span></label
                  >
                  <textarea
                    type="text"
                    v-model="description"
                    class="form-control custom-text-area-scroll"
                    placeholder="Type something about report reason"
                    autocomplete="off"
                    rows="2"
                    maxlength="250"
                  ></textarea>
                  <div class="custom-error" v-if="v$.description.$error">
                    {{ v$.description.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-12 m-0 text-end">
                <button
                  class="header-btn-outer btn btn-primary"
                  @click="submitReport()"
                  :disabled="isBtnDisabled"
                >
                  Report visitor
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end for report modal form -->
    </div>
  </div>
</template>

<script>
import ApiService from "../../../service/ApiService";
import commonMixin from "../../../mixins/commonMixin";
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  mixins: [commonMixin],
  data() {
    return {
      v$: useValidate(),
      btnloader: false,
      searchTerm: "",
      guestList: [],
      loading: false,
      totalRecords: 0,
      page_no: 0,
      selectedGuest: null,
      isReportModalOpen: false,
      reportedReason: null,
      reportedReasonList: [],
      description: "",
      activate_read_key_arr: [],
      aa1: "",
      isBtnDisabled: false,
      currentDate: new Date(),
      hoststorageimgpath:""
    };
  },
  validations() {
    return {
      reportedReason: {
        required: helpers.withMessage("Please Enter Reason", required),
      },
      description: {
        required: helpers.withMessage("Please Enter Description.", required),
      },
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.getReportReasonsList();
    this.client_info = JSON.parse(localStorage.client_info);
    this.hoststorageimgpath = this.client_info.maa24;
  },
  methods: {
    getHostVisitorHistoryList(e) {
      this.loading = true;
      this.activate_read_key_arr = [];
      this.ApiService.getHostVisitorHistoryList(e).then((data) => {
        if (data.success == true) {
          this.guestList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.guestList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
        this.searchnameloader = false;
      });
    },
    searchReportingUser(search) {
      this.getHostVisitorHistoryList({
        page_no: this.page_no,
        aa9: search,
      });
      this.searchnamebtnactive = true;
    },

    addReportModalOpen(data) {
      this.isReportModalOpen = true;
      this.aa1 = data;
    },

    addReportModalClose() {
      this.isReportModalOpen = false;
    },

    getReportReasonsList() {
      this.loading = true;
      this.ApiService.getReportReasonsList().then((data) => {
        if (data.success == true) {
          this.reportedReasonList = data.data;
          this.loading = false;
        } else {
          this.reportedReasonList = null;
          this.loading = false;
        }
      });
    },
    submitReport() {
      this.loading = true;
      this.isBtnDisabled = true;
      this.v$.$validate();
      var formData = new FormData();
      formData.append("aa1", this.aa1);
      formData.append("aw6", this.reportedReason.value);
      formData.append("aw7", this.reportedReason.label);
      formData.append("aw8", this.description);
      this.ApiService.reportVisitor(formData)
        .then((data) => {
          if (data.success == true) {
            this.isReportModalOpen = false;
            this.$router.push("/guests/reports");
          }
        })
        .catch(($error) => {
          console.log("error", $error);
        });
        this.searchTerm = "";
    },
    isExpired(date) {
      const expiryDate = new Date(date);
      return expiryDate <= new Date(this.currentDate);
    },
  },
};
</script>

<style scoped>
.form-box-outer {
  padding: 16px 16px;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.search-user-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 512px;
}

.search-user-section-outer::-webkit-scrollbar {
  display: block;
}
.search-user-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.search-user-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 514px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.company-detail-box-outer {
  padding: 16px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}
.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.customer-label-group {
  margin-bottom: 20px;
}
.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}
.blink_me {
  animation: blinker 1s linear infinite;
  color: #dc3545;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>